import { Helmet } from 'react-helmet';
import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

export interface SeoProps {
  articleAuthor?: string;
  articleModified?: string;
  articlePublished?: string;
  articlePublisher?: string;
  articleSection?: string;
  articleTag?: string;
  description: string;
  ogImageContent?: string;
  ogImageHeight?: number;
  ogImageWidth?: number;
  ogType?: string;
  path?: string;
  profileUsername?: string;
  title: string;
}

const Seo: React.FC<SeoProps> = (props) => {
  const {
    articleAuthor,
    articleModified,
    articlePublished,
    articlePublisher,
    articleSection,
    articleTag,
    description,
    ogImageContent,
    ogImageHeight,
    ogImageWidth,
    ogType,
    path,
    profileUsername,
    title,
  } = props;
  const { site, ogImage } = useStaticQuery(seoQuery);
  const finalDescription = description || site.siteMetadata.description;
  const { author, siteUrl, siteName } = site.siteMetadata;
  const imageUrl = ogImageContent || ogImage.publicURL;
  const imageWidth = ogImageWidth || ogImage.childImageSharp.fixed.width;
  const imageHeight = ogImageHeight || ogImage.childImageSharp.fixed.height;
  const url = path && `${siteUrl}${path}`;

  return (
    <>
      <Helmet>
        <title>
          {props.title} - {siteName}
        </title>
        {[
          { name: 'description', content: finalDescription },
          { name: 'author', content: author },
          { property: 'og:site_name', content: siteName },
          { property: 'og:locale', content: 'en_US' },
          { property: 'og:title', content: props.title },
          { property: 'og:description', content: finalDescription },
          { property: 'og:url', content: url },
          { property: 'og:type', content: ogType },
          { property: 'profile:username', content: profileUsername },
          { property: 'og:image', content: `${siteUrl}${imageUrl}` },
          {
            property: 'og:image:secure_url',
            content: `${siteUrl}${imageUrl}`,
          },
          { property: 'og:image:width', content: imageWidth },
          { property: 'og:image:height', content: imageHeight },
          { property: 'article:published_time', content: articlePublished },
          { property: 'article:modified_time', content: articleModified },
          { property: 'article:section', content: articleSection },
          { property: 'article:tag', content: articleTag },
          { property: 'article:publisher', content: articlePublisher },
          { property: 'article:author', content: articleAuthor },
          { name: 'twitter:card', content: 'summary' },
          { name: 'twitter:creator', content: site.siteMetadata.author },
          { name: 'twitter:title', content: title },
          { name: 'twitter:description', content: finalDescription },
          { name: 'twitter:image', content: `${siteUrl}${imageUrl}` },
        ].map((m) => m.content && <meta key={m.name || m.property} {...m} />)}
        {url && <link rel="canonical" href={url} />}
      </Helmet>
    </>
  );
};

export default Seo;

const seoQuery = graphql`
  query SeoQuery {
    site {
      siteMetadata {
        author
        siteName
        siteUrl
      }
    }
    ogImage: file(relativePath: { eq: "og-img.jpg" }) {
      publicURL
      childImageSharp {
        fixed(width: 500) {
          width
          height
        }
      }
    }
  }
`;
